import DocumentContext from "../../../providers/DocumentContext"
import TemplateTaskLib from "@common/libs/template-lib"
import { Button } from "@material-ui/core/"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import config from "~/config"
import TemplateLibInterview from "@common/libs/interview-template-lib"
import { toast } from "react-toastify"
import { CloudDownload, CloudUpload } from "@material-ui/icons"
import { CircularProgress } from "@material-ui/core"

const useStyles = makeStyles(({ palette }) => ({
  button: {
    marginTop: 50,
    height: 60,
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
    backgroundColor: palette.cta.dark,
    "&:hover": {
      backgroundColor: palette.cta.main,
    },
    "&:disabled": {
      backgroundColor: "silver",
      color: "grey",
    },
  },

  project: {
    margin: "5px auto",
    padding: "4px 9px",
    borderRadius: 5,
    fontWeight: "bold",
    textAlign: "center",
    maxWidth: 400,
  },

  wordButton: {
    display: "flex",
    alignItems: "center",
  },
}))

export default function SaveButtons() {
  const classes = useStyles()
  const { document, updateDocument } = useContext(DocumentContext)

  const [projectName, setprojectName] = useState(null)
  const [taskName, settaskName] = useState(null)

  let [loading, setLoading] = useState(false)

  const uploadFile = () => {
    const input = window.document.getElementById("file-input")

    setLoading(true)

    TemplateLibInterview.createDocumentDocx(document.id, input.files[0])
      .then((result) => {
        setLoading(false)
        input.value = ""
        toast.success("Le fichier a bien été mis à jour.")
      })
      .catch((error) => {
        setLoading(false)
        input.value = ""
        toast.error(error.response.data.errors)
      })
  }
  function downloadBlob(blob, name = "file.txt") {
    const blobUrl = URL.createObjectURL(blob)

    const link = window.document.createElement("a")

    link.href = blobUrl
    link.download = name

    window.document.body.appendChild(link)

    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    )

    window.document.body.removeChild(link)
  }

  const downloadFile = () => {
    let extension = document.pdf ? ".pdf" : ".docx";
    TemplateLibInterview.downloadDocumentDocx(document.id).then((result) => {
      downloadBlob(result, document.name + extension);
    })
  }

  const downloadIndentedFile = () => {
    TemplateLibInterview.downloadIndentedDocumentDocx(document.id).then(
      (result) => {
        downloadBlob(result, document.name + "_indenté.docx")
      }
    )
  }

  const downloadAnnotatedPdfFile = () => {
    TemplateLibInterview.downloadAnnotatedPdfFile(document.id).then(
      (result) => {
        downloadBlob(result, document.name + "_annoté.pdf")
      }
    )
  }

  React.useEffect(() => {
    if (document.task) {
      TemplateTaskLib.getSpecificTask(document.task)
        .then((res) => {
          settaskName(res.name)
        })
        .catch(() => settaskName("/"))
    } else settaskName("/")

    TemplateTaskLib.getProject(document.project)
      .then((res) => {
        setprojectName(res.name)
      })
      .catch(() => settaskName("/"))
  }, [document.project])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        minHeight: 450,
      }}
    >
      <Button
        fullWidth
        variant="contained"
        component="label"
        color="primary"
        style={{ marginBottom: 10 }}
        disabled={loading}
      >
        {loading && (
          <div className={classes.wordButton}>
            <CircularProgress style={{ marginRight: 10 }} />
            Traitement du fichier... Patientez
          </div>
        )}
        {!loading && (
          <div className={classes.wordButton}>
            <CloudUpload style={{ marginRight: 10 }} />
            Mettre à jour le document
          </div>
        )}
        <input
          hidden
          accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          id="file-input"
          type="file"
          onChange={() => {
            uploadFile()
          }}
        />
      </Button>

      <Button
        fullWidth
        variant="contained"
        component="label"
        color="primary"
        style={{ marginBottom: 10 }}
        onClick={downloadFile}
      >
        <CloudDownload style={{ marginRight: 10 }}></CloudDownload>
        Télécharger le document
      </Button>

      {!document.pdf && (
        <Button
          fullWidth
          variant="contained"
          component="label"
          color="primary"
          onClick={downloadIndentedFile}
        >
          <CloudDownload style={{ marginRight: 10 }}></CloudDownload>
          Indenter le document Word
        </Button>
      )}

      {document.pdf && (
        <Button
          fullWidth
          variant="contained"
          component="label"
          color="primary"
          onClick={downloadAnnotatedPdfFile}
        >
          <CloudDownload style={{ marginRight: 10 }}></CloudDownload>
          Annoter le formulaire PDF
        </Button>
      )}

      {/* {document.pdf && (
        <div>
          <h3>Mapping PDF</h3>
        </div>
      )} */}
    </div>
  )
}
